import { Box, Button, Container, TextInput, Title } from '@mantine/core';
import { isEmail, useForm } from '@mantine/form';
import React, { useState } from 'react';

import classes from './devise.module.css';
import SignInNextStep from './SignInNextStep';

export type SnappeaNewSignInProps = {
  token: string;
};

type FormValues = {
  email: string;
}

const SnappeaNewSignIn: React.FC<SnappeaNewSignInProps> = ({ token }) => {
  const form = useForm<FormValues>({
    initialValues: {
      email: '',
    },
    validate: {
      email: isEmail('Invalid email'),
    },
  });
  const [next, setNext] = useState(false);

  const handleSubmit = form.onSubmit(() => setNext(true));

  return (
    <Container fluid mih="50vh">
      <Title order={2} mb="md">Sign In</Title>
      <Box component="form" onSubmit={handleSubmit} maw="20%">
        <TextInput
          label="Email"
          placeholder="Your email"
          className={classes.InputOverride}
          {...form.getInputProps('email')}
          mb="md"
          autoComplete="username"
          autoFocus
        />
        <Button type="submit" variant="default" loading={next}>Sign in</Button>
      </Box>
      {next ? (
        <SignInNextStep
          email={form.values.email}
          token={token}
          onError={() => setNext(false)}
          onComplete={() => setNext(false)}
        />
      ) : null}
    </Container>
  );
};

export default SnappeaNewSignIn;
