import { useQuery } from '@apollo/client';
import React from 'react';

import { getAuthProvider } from '@/api/app/get_auth_provider';
import HiddenForm from '@/components/shared/mantine/HiddenForm';
import { wentWrong } from '@/helpers/notification_helpers';

type SignInNextStepProps = {
  email: string;
  token: string;
  onError(): void;
  onComplete(): void;
};

const SignInNextStep: React.FC<SignInNextStepProps> = ({ email, token, onError, onComplete }) => {
  const { data, error } = useQuery(getAuthProvider, { variables: { email } });

  if (error) {
    onError();
    wentWrong(error?.message ?? '');
    return <></>;
  }

  // waiting for data
  if (!data?.data?.providerUrl) {
    return <></>;
  }

  const fields = {
    login_hint: email,
    authenticity_token: token,
  };

  return (
    <HiddenForm
      method="post"
      action={data.data.providerUrl}
      fields={fields}
      onComplete={onComplete}
    />
  );
};

export default SignInNextStep;
