import React from 'react';

import { AdminProvider } from '@/components/shared/Provider';
import SnappeaNewSignIn, { SnappeaNewSignInProps } from '@/components/snappea/devise/SnappeaNewSignIn';
import { createContainer } from '@/helpers/container_helper';

const NewSignInEntrypoint: React.FC<SnappeaNewSignInProps> = (props) => (
  <AdminProvider>
    <SnappeaNewSignIn {...props} />
  </AdminProvider>
);

createContainer('devise-new-sign-in', NewSignInEntrypoint);
